import styled from "styled-components";

export const Button = styled.button`
	font-size: 20px;
	font-weight: 600;
	color: #ffffff;

	border: none;
	border-radius: 6px;
	outline: none;
	cursor: pointer;

	border-radius: 5px;
	padding: 18px 6px 18px 6px;
	background: linear-gradient(
		271.82deg,
		#00568c 2.6%,
		#0088b2 35.43%,
		#3dbb95 80.64%
	);
`;
